import request from '@/utils/request'

//-------------账号管理---------

//获取banner列表
export function FindBannnerList(data) {
  return request({
    url: '/banner/FindBannnerList',
    method: 'post',
    params: data
  })
}

//获取活动列表
export function FindActivityList(data) {
  return request({
    url: '/banner/FindActivityList',
    method: 'post',
    params: data
  })
}

//删除banner
export function DelBanner(data) {
  return request({
    url: '/banner/DelBanner',
    method: 'post',
    params: data
  })
}

//修改banner
export function UpDateBanner(data) {
  return request({
    url: '/banner/UpDateBanner',
    method: 'post',
    params: data
  })
}

//新增banner
export function AddBannner(data) {
  return request({
    url: '/banner/AddBannner',
    method: 'post',
    params: data
  })
}
//上架banner
export function putawayBanner(data) {
  return request({
    url: '/banner/UpLookBanner',
    method: 'post',
    params: data
  })
}
//上传图片
export function Miniupload(data) {
  return request({
    url: '/fileutil/Miniupload',
    method: 'post',
    params: data
  })
}


//banner类型
export function bannerConfigType(data) {
  return request({
    url: '/banner/BannerconfigType',
    method: 'post',
    params: data
  })
}
